<template>
    <child-layout title="Import Logs Details">
        <ag-grid-server-side
            id="importLogDetails"
            :server-side-datasource="serverSideDatasource"
            suppress-row-click-selection="true"
            :column-defs="columnDefs"
            :default-sort="{ dateTime: 'asc' }"
        />
    </child-layout>
</template>

<script>
import ChildLayout from '@/components/layout/ChildLayout'
import AgGridServerSide from '@/components/table/AgGridServerSide'
import ColumnDef from '@/helpers/ag-grid/columnDef/importLog/details'
import { mapActions, mapGetters } from 'vuex'
import DataSource from '@/helpers/ag-grid/datasource/graphql'

export default {
    name: 'ImportLogDetails',
    components: {
        ChildLayout,
        AgGridServerSide,
    },
    data: () => ({
        columnDefs: ColumnDef,
    }),
    computed: {
        ...mapGetters({
            fetching: 'importLogDetails/getFetching',
            items: 'importLogDetails/getItems',
            lea: 'getDistrictLea',
        }),
        batchId() {
            return this.$route.params.batchId
        },
        etlStatusId() {
            return this.$route.params.etlStatusId
        },
    },
    methods: {
        ...mapActions({
            fetchRows: 'importLogDetails/get',
        }),
        serverSideDatasource: function () {
            const fetch = async (params) => {
                await this.fetchRows({
                    lea: this.lea,
                    batchId: this.batchId,
                    etlStatusId: this.etlStatusId,
                    ...params,
                })

                return this.items
            }

            return {
                getRows: DataSource.partialStore(fetch, 'importLogDetails').getRows,
            }
        },
    },
}
</script>

<style scoped>

</style>